"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");

function _interopDefault(e) {
  return e && "object" == _typeof(e) && "default" in e ? e.default : e;
}

Object.defineProperty(exports, "__esModule", {
  value: !0
});

var React = _interopDefault(require("react")),
    classCallCheck = function classCallCheck(e, t) {
  if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
},
    inherits = function inherits(e, t) {
  if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + _typeof(t));
  e.prototype = Object.create(t && t.prototype, {
    constructor: {
      value: e,
      enumerable: !1,
      writable: !0,
      configurable: !0
    }
  }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : e.__proto__ = t);
},
    possibleConstructorReturn = function possibleConstructorReturn(e, t) {
  if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  return !t || "object" != _typeof(t) && "function" != typeof t ? e : t;
},
    AppContainer = function (e) {
  function t() {
    return classCallCheck(this, t), possibleConstructorReturn(this, e.apply(this, arguments));
  }

  return inherits(t, e), t.prototype.render = function () {
    return React.Children.only(this.props.children);
  }, t;
}(React.Component),
    hot_prod = function hot_prod() {
  return function (e) {
    return e;
  };
},
    areComponentsEqual = function areComponentsEqual(e, t) {
  return e === t;
},
    setConfig = function setConfig() {},
    cold = function cold(e) {
  return e;
},
    configureComponent = function configureComponent() {};

exports.AppContainer = AppContainer, exports.hot = hot_prod, exports.areComponentsEqual = areComponentsEqual, exports.setConfig = setConfig, exports.cold = cold, exports.configureComponent = configureComponent;